import React, { useState } from "react";
import Stars from "../../components/global/Logout/Stars";



function Faq() {





    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [

        {
            UserName: "Jackie W.",
            Date: "07/26/2021",
            Rate: 5,
            Description: "This is an amendment to a review I started for Frank and accidentally hit send. As I was saying, he did a retaining wall and then modified the upper level with sod, mulch, and some pavers around a bench. He is truly an artisan, communicates well with a client, and does everything to make sure you are completely happy. We certainly are and I heartily recommend him if you are looking at any hardscape projects.",
            red: "Porch",
            enlace: "https://pro.porch.com/dana-nc/landscapers/franks-hardscape-inc/pp",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Directorios%2FPorchLog.png?alt=media&token=1bf2316b-4147-434a-9cd8-e7723c52710b"
        },
        {
            UserName: "Jackie W.",
            Date: "07/26/2021",
            Rate: 5,
            Description: "Frank and the guys that work with him Just finished replacing a retaining wall for us. They also did an area with sod and one with mulch for plantings. They showed up when they said they would every day, and when they were gone at the end of the day, it looked like they had never been here as they kept the site cleaned up. Frank definitely knows what he is doing and we could not be more pleased with the project",
            red: "Porch",
            enlace: "https://pro.porch.com/dana-nc/landscapers/franks-hardscape-inc/pp",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Directorios%2FPorchLog.png?alt=media&token=1bf2316b-4147-434a-9cd8-e7723c52710b"
        },
        {
            UserName: "Sung P.",
            Date: "03/05/2020",
            Rate: 5,
            Description: "Highly recommend Franks Hardscape. He was punctual, fair and did an outstanding job on the repair.",
            red: "Porch",
            enlace: "https://pro.porch.com/dana-nc/landscapers/franks-hardscape-inc/pp",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Directorios%2FPorchLog.png?alt=media&token=1bf2316b-4147-434a-9cd8-e7723c52710b"
        },
        {
            UserName: "Monisha B.",
            Date: "05/25/2018",
            Rate: 5,
            Description: "Frank's work went way above and beyond my expectations. I was looking for new sod, beds around the perimeter, and some generic fire pit area. I trusted him with the design and plant selections, etc. (not wanting to put much thought or effort into the process). It turned out fantastic. His bid was extremely reasonable. As a younger single woman, I feel as though many contractors do not take me seriously, but he was professional and reliable throughout the process. He showed up when he said he would and stayed on budget. I have already recommended him to others and will definitely plan on using him for future projects.",
            red: "Porch",
            enlace: "https://pro.porch.com/dana-nc/landscapers/franks-hardscape-inc/pp",
            ima: "https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Directorios%2FPorchLog.png?alt=media&token=1bf2316b-4147-434a-9cd8-e7723c52710b"
        }

    ];


    return (
        <section className="w-full flex flex-col justify-center items-center">
            <h2 className="text-center">Our Reviews</h2>
            <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
                {
                    moverReview.map((item, index) => {
                        return (
                            <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                                <div className="flex flex-col">
                                    <div className="p-2 flex space-x-2">
                                        <div className="flex justify-center">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                                alt="perfil-foto"
                                                className="w-[50px] h-auto rounded-full object-cover" />
                                        </div>
                                        <div>
                                            <span className="text-[20px] font-semibold"> {item.UserName} </span>
                                            <Stars score={item.Rate} />
                                        </div>
                                        <div className="flex items-end text-[13px] text-gray-400">
                                            <span>{item.Date}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-5 ">
                                    <ReadMore >{item.Description}</ReadMore>
                                </div>
                                <div className="w-full flex items-center space-x-3 ml-3">
                                    <div className="w-[25px] h-[25px] flex items-center">
                                        <img src={item.ima} alt="red" className="w-full mx-auto" />
                                    </div>
                                    <div className="flex flex-col items-center justify-start">
                                        <p>Posted On</p>
                                        <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                    </div>
                                </div>
                            </article>
                        );
                    })
                }
            </section>
        </section>

    );
}

export default Faq;